import { actionType } from '../actions/user';
import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';

const initState = {
  ...baseInitState,
};

export default function user(state = initState, action) {
  switch (action.type) {
    case actionType('verifyUser').request:
      return {
        ...state,
        isLoadingSubmit: true,
      };
    case actionType('verifyUser').success:
    case actionType('verifyUser').error:
      return {
        ...state,
        isLoadingSubmit: false,
      };

    default:
      return baseReducer(state, action, actionType);
  }
}
