import { URL_API_AUTH } from 'src/config';
import httpService from 'src/helpers/httpService';

const path = 'users';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const request = httpService();
const user = {
  fetchList: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchList').request });
      return request.get(`${URL_API_AUTH}/users/list/admin`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchList').success, response: response.data, params });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchList').error });
          throw error.response;
        },
      );
    };
  },
  verifyUser: (body) => {
    return (dispatch) => {
      dispatch({ type: actionType('verifyUser').request });
      return request.put(`${URL_API_AUTH}/users/verify`, body).then(
        (response) => {
          dispatch({ type: actionType('verifyUser').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('verifyUser').error });
          throw error.response;
        },
      );
    };
  },
};
export default user;
