import { URL_API_AUTH } from 'src/config';
import baseActions from '../baseActions';

const path = 'companies';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const company = {
  ...baseActions(URL_API_AUTH, path, actionType),
};
export default company;
